<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn v-if="$route.params.id" @click="editMode = !editMode">
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <h2 class="mb-4">Bilgiler</h2>
          <v-row>
            <v-col cols="4">
              <v-text-field
                hide-details="auto"
                v-model="currentSalePeriod.name"
                label="Satış Dönemi Adı"
                :rules="[
                  (v) => !!v || 'Satış dönemi adı alanı boş bırakılamaz',
                  (v) =>
                    v?.length >= 3 ||
                    'Satış dönemi adı alanı en az 3 karakterden oluşmalıdır',
                ]"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedStartDate"
                    label="Baslangıç Tarihi"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Başlangıç tarihi alanı boş bırakılamaz',
                    ]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentSalePeriod.starts_on"
                  no-title
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedEndDate"
                    label="Bitiş Tarihi"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Bitiş tarihi alanı boş bırakılamaz',
                    ]"
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentSalePeriod.ends_on"
                  no-title
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model.number="currentSalePeriod.goldenmade_goal"
                label="Goldenmade Hedefi"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model.number="currentSalePeriod.platiniummade_goal"
                label="Platiniummade Hedefi"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model.number="currentSalePeriod.siliconmade_goal"
                label="Siliconmade Hedefi"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model.number="currentSalePeriod.siliconhead_goal"
                label="Siliconhead Hedefi"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="saleGoal"
                label="Satış Hedefi"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-content-end">
                <v-btn class="mr-3" color="error" @click="reset"> İptal </v-btn>
                <v-btn
                  class="mr-3"
                  color="primary"
                  :disabled="!isFormValid"
                  @click="save"
                >
                  Kaydet
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveAndClose"
                >
                  Kaydet ve Kapat
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import Card from "@/view/content/components/Card";
import { mapActions, mapGetters } from "vuex";
import {
  GET_SALE_PERIOD,
  CREATE_SALE_PERIOD,
  UPDATE_SALE_PERIOD,
} from "@/core/services/store/definitions/sale-periods.module";
import { currencyMask } from "@/core/helper";

export default {
  name: "SalePeriodEdit",
  components: { Card },
  data() {
    return {
      editMode: false,
      currentSalePeriod: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      try {
        const salePeriod = await this.getSalePeriod(this.$route.params.id);
        this.currentSalePeriod = Object.assign({}, salePeriod);
      } catch (error) {
        this.$router.push({ name: "def-sale-periods" });
      } finally {
        this.loading = false;
      }
    }
    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([GET_SALE_PERIOD, CREATE_SALE_PERIOD, UPDATE_SALE_PERIOD]),
    currencyMask,
    formatDate(date) {
      if (!date) return null;

      return this.$moment(date).format("DD/MM/YYYY");
    },
    async save(replaceRoute = true) {
      if (this.$route.params.id) {
        try {
          const salePeriod = JSON.parse(JSON.stringify(this.currentSalePeriod));
          const data = {
            ...salePeriod,
            id: this.$route.params.id,
          };
          await this.updateSalePeriod(data);
          this.$toast.success("Satış dönemi güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const salePeriod = await this.createSalePeriod(
            this.currentSalePeriod
          );
          if (replaceRoute) {
            this.$router.push({
              name: "def-sale-periods-edit",
              params: {
                id: salePeriod.id,
              },
              query: { editable: true },
            });
          }
          this.$toast.success("Satış dönemi yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-sale-periods" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentSalePeriod = Object.assign({}, this.salePeriod);
      } else {
        this.currentSalePeriod = {};
      }
      this.$router.push({ name: "def-sale-periods" });
    },
  },
  computed: {
    ...mapGetters(["salePeriod"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Satış Dönemi Düzenle";
        return "Satış Dönemi Detay";
      }
      return "Satış Dönemi Yaratma";
    },
    formattedStartDate() {
      return this.formatDate(this.currentSalePeriod.starts_on);
    },
    formattedEndDate() {
      return this.formatDate(this.currentSalePeriod.ends_on);
    },
    saleGoal() {
      const goals = [
        this.currentSalePeriod.goldenmade_goal,
        this.currentSalePeriod.platiniummade_goal,
        this.currentSalePeriod.siliconmade_goal,
        this.currentSalePeriod.siliconhead_goal,
      ];
      return goals.reduce((a, b) => (a + b !== null ? b : 0), 0);
    },
  },
  watch: {
    saleGoal(val) {
      this.currentSalePeriod.sale_goal = val;
    },
  },
};
</script>
